// core version + navigation, pagination modules:
import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';

// configure Swiper to use modules
Swiper.use([Navigation]);

const container = document.querySelector('#instagram .swiper-container');

const config = {
  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  spaceBetween: 20,
  slidesOffsetBefore: 50,
  slidesOffsetAfter: 50,
  grabCursor: true,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      spaceBetween: 15,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      slidesOffsetBefore: 15,
      slidesOffsetAfter: 15,
      spaceBetween: 15,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 3,
    },
    // when window width is >= 950px
    850: {
      slidesPerView: 5,
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const swiper = new Swiper(container, config);
});


