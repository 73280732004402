
// Get the nodes
let burgerMenu = document.querySelector('.burger-menu');
let menu = document.querySelector('.menu');

// Apply click event on it
burgerMenu.addEventListener('click', (evt) => {

    $this = evt.currentTarget;
    // Give active class on click
    if (menu.classList.contains('active')) {
        $this.classList.remove('-active');
        menu.classList.remove('active');
    } else {
        $this.classList.add('-active');
        menu.classList.add('active');
    }
});

let hasSubmenu = document.querySelectorAll('.has-submenu');

// Check submenu
for (let i = 0; i < hasSubmenu.length ; i++) {
    hasSubmenu[i].addEventListener('click', function (evt) {
        const innerWidth = window.innerWidth;
        if (innerWidth < 991) {
            const target = evt.target;
            // Cancel default event
            if (!target.classList.contains('parent-link')) {
                return;
            }

            evt.preventDefault();

            // Variables
            const $this = evt.currentTarget;
            // Calc elem height
            const submenu = $this.querySelector('.submenu');
            // Toggle show items
            toggleShow(submenu)
        }
    })
}

const getHeight = (elem) => {
    elem.style.display = 'block';
    let height = elem.scrollHeight + 'px';
    elem.style.display = '';
    return height;
};

const show = (elem) => {
    const height = getHeight(elem); // Get the natural height
    elem.classList.add('is-visible'); // Make the element visible
    elem.style.height = height; // Update the max-height
    window.setTimeout(function () {
        elem.style.height = '';
    }, 350);
};

const hide = (elem) => {
    // Give the element a height to change from
    elem.style.height = elem.scrollHeight + 'px';

    // Set the height back to 0
    window.setTimeout(function () {
        elem.style.height = '0';
    }, 1);

    // When the transition is complete, hide it
    window.setTimeout(function () {
        elem.classList.remove('is-visible');
    }, 350);
};

const toggleShow = (elem) => {
    if (elem.classList.contains('is-visible')) {
        hide(elem);
        return;
    }
    show(elem);
};
