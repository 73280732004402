// core version + navigation, pagination modules:
import Swiper, { Navigation } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation]);

const container = document.querySelector('.container-reviews .swiper-container');
const config = {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  autoplay: {
      delay: 2500,
      disableOnInteraction: true,
  },
  slidesPerView: 1,
  spaceBetween: 0,
  slidesPerColumn: 1,
  slidesOffsetBefore: 0,
  slidesOffsetAfter: 0,
  centeredSlides: true,
  loop: true
}

window.addEventListener('load', () => {
  new Swiper(container, config);
})

