import ScrollReveal from 'scrollreveal'

const defaultReveal = {
    distance: '100px',
    origin: 'top',
    duration: 700
};

const revealLeft = {
    distance: '100px',
    origin: 'left',
    duration: 700,
};

const revealRight = {
    distance: '100px',
    origin: 'right',
    duration: 700,
};

const revealTop = {
    distance: '100px',
    origin: 'top',
    duration: 700,
};

document.addEventListener("DOMContentLoaded", function() {
  ScrollReveal().reveal('.reveal.-default', defaultReveal);
  ScrollReveal().reveal('.reveal.-left', revealLeft);
  ScrollReveal().reveal('.reveal.-right', revealRight);
  ScrollReveal().reveal('.reveal.-top', revealTop);
});
