const nav = document.querySelector('.nav');
let scrollObject;
let lastPos = 0;

window.onscroll = () => {

    scrollObject = {
        x: window.scrollX,
        y: window.scrollY
    };

    if (scrollObject.y < 80) {
        nav.classList.remove('hidden-nav');
    } else {
        if (lastPos > scrollObject.y) {
            nav.classList.remove('hidden-nav');
        } else {
            nav.classList.add('hidden-nav');
        }
    }


    lastPos = scrollObject.y
};
