import IMask from 'imask';

const inputs =  document.querySelectorAll('.input.mask');

let phoneConfig = {
    mask: '00 00 00 00 00'
};

if (inputs) {
    inputs.forEach((input) => {
        // Get input option
        const type = input.getAttribute('data-type');

        // Switch to apply mask for each input option
        switch (type) {
            case 'phone':
                // Apply the IMask phone option
                IMask(input, phoneConfig);
                break;
            case 'email':
                break;
            default:
                break;
        }
    });
}
